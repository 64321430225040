import React, {Component} from 'react';
import {Alert, Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import authService from '../api-authorization/AuthorizeService';

export default class NewOrganization extends Component {
    static displayName = NewOrganization.name;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: null
                , email: null
                , website: null
            }
            , errors: []
            , isLoading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            fetch('/api/v1/organization', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + await authService.getAccessToken()
                }
                , method: 'post'
                , body: JSON.stringify(this.state.form)
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        this.props.history.push("/admin/organizations")
                        return null;
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Nuova associazione</h1>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Form onSubmit={this.handleSubmit}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <FormGroup>
                        <Label>Nome dell'associazione:</Label>
                        <Input type={'text'}
                               placeholder={'Nome dell\'associazione'}
                               name={'name'}
                               value={this.state.form.name}
                               minLength={1}
                               maxLength={50}
                               onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Email dell'associazione:</Label>
                        <Input type={'email'}
                               placeholder={'Email dell\'associazione'}
                               name={'email'}
                               value={this.state.form.email}
                               minLength={1}
                               maxLength={50}
                               onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Sito web dell'associazione:</Label>
                        <Input type={'text'}
                               placeholder={'Sito web dell\'associazione'}
                               name={'website'}
                               value={this.state.form.website}
                               minLength={1}
                               maxLength={50}
                               onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button color={'primary'} type="submit">Crea</Button>
                </Form>
            </div>
        );
    }
}
