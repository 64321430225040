import React from "react";
import {Alert, Button, Form, FormGroup, Input, Label, Spinner, Table} from "reactstrap";
import {Link} from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";

export default class Users extends React.Component {
    static displayName = Users.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , users: []
            , form: {
                org: !this.props.match.params.id ? null : this.props.match.params.id,
                username: null
            },
            errors: []
            , user: {}
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.load = this.load.bind(this)
        this.reset = this.reset.bind(this)
        this.delete = this.delete.bind(this)
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    async handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        let token = await authService.getAccessToken()

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, () => {
            fetch('/api/v1/users/', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + token
                }
                , method: 'post'
                , body: JSON.stringify(this.state.form)
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        this.load()
                        return null;
                    }

                    if (r.status === 409) {
                        this.setState({
                            ...this.state
                            , isLoading: false
                            , errors: ['Nome utente già in uso']
                        })
                        return null
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    state.form.username = null
                    this.setState(state)
                })
        })
    }

    async delete(id) {
        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            fetch("/api/v1/users/" + id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + await authService.getAccessToken()
                }
                , method: 'delete'
            }).then(r => {
                if (r.status === 204 || r.status === 200) {
                    this.load()
                    return null;
                }

                return r.json()
            })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    async reset(id) {
        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            await fetch("/api/v1/users/reset/" + id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + await authService.getAccessToken()
                }
                , method: 'post'
            })
                .then(r => {
                    if (r.status === 200) {
                        r.text().then(t => alert('La nuova password è: ' + t.substr(1, t.length - 2) + '\nCopiala immediatamente, non potrai più vederla successivamente.'))
                        this.setState({
                            ...this.state
                            , isLoading: false
                        })
                        return null;
                    }
                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })

        })
    }

    async componentDidMount() {
        console.log(await authService.getUser())
        this.setState({
            ...this.state
            , user: await authService.getUser()

        }, async () => await this.load())

    }

    async load() {
        fetch("/api/v1/Users" + (this.state.form.org !== null ? '?org=' + this.state.form.org : ''), {
            headers: {
                'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(async r => {
                this.setState({
                    ...this.state
                    , isLoading: false
                    , users: r
                })
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Utenti {(this.state.form.org !== null) ? 'dell\'associazione' : 'del Comune'}</h1>
                <div style={{
                    marginBottom: '20px'
                }}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <Form onSubmit={this.handleSubmit}>
                        <div className={'form-row'}>

                            <FormGroup style={{
                                marginRight: '12px'
                            }}>
                                <Label for={'username'}>Nome utente</Label>
                                <Input type={'text'}
                                       id={'username'}
                                       name={'username'}
                                       onChange={this.handleChange}
                                       value={this.state.form.username}/>
                            </FormGroup>
                        </div>
                        <div className={'form-row'}>
                            <FormGroup>
                                <Button color="primary" type={'submit'}>Crea nuovo utente</Button>
                            </FormGroup>
                        </div>
                    </Form>
                </div>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome utente
                        </th>
                        <th>
                            Azioni
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.users.length === 0 && (
                            <tr>
                                Nessun utente presente.
                            </tr>
                        )
                    }
                    {
                        this.state.users.map(o => <tr>
                            <td>
                                {o.username}
                            </td>
                            <td>
                                <Button color={'primary'}
                                        size={'sm'}
                                        onClick={() => this.reset(o.id)}>Nuova password</Button>
                                {
                                    this.state.user.org === undefined && (
                                        <Button color={'danger'}
                                                style={{
                                                    marginLeft: '5px'
                                                }}
                                                size={'sm'}
                                                onClick={() => this.delete(o.id)}>Elimina</Button>
                                    )
                                }
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}