import React, {Component} from 'react';

export class Card extends Component {
    static displayName = Card.name;

    render() {
        return (
            <div style={{
                padding: '12px'
                , margin: '12px'
                , boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
                , borderRadius: '8px'
            }}>
                {this.props.children}
            </div>
        )
    }
}
