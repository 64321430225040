import React, {Component} from 'react';
import {Link, useHistory} from 'react-router-dom'
import {MapContainer, TileLayer, Marker, Popup, ZoomControl, Circle} from 'react-leaflet'
import * as ReactDOMServer from "react-dom/server";
import L from 'leaflet';
import MapMarker from "./MapMarker";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            parks: []
        }
    }

    componentDidMount() {
        fetch("/api/v1/Map/ComputeMap")
            .then(r => r.json())
            .then(r => this.setState({
                ...this.state
                , parks: r
            }))
    }

    createMarker(park) {
        let icon = L.divIcon({
            className: 'custom-icon',
            html: ReactDOMServer.renderToString(<MapMarker value={park.events_count}/>)
        });

        let _self = this

        return <Marker key={park.park_id} position={{
            lat: park.lat
            , lon: park.lon
        }} icon={icon} eventHandlers={{
            click: () => {
                _self.props.history.push("/events/?parkId=" + park.park_id)
            }
        }}/>
    }

    render() {
        return (
            <div style={{
                position: 'relative'
                , width: '100%'
                , height: '100%'
            }}>
                <MapContainer style={{
                    position: 'relative'
                    , width: '100%'
                    , height: '100%'
                }}
                              center={[44.2163406, 12.0482844]}
                              zoom={13}
                              scrollWheelZoom={true}
                              zoomControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ZoomControl position="bottomright"/>
                    {
                        this.state.parks.map(p => this.createMarker(p))
                    }
                </MapContainer>
            </div>
        );
    }
}
