import React, {Component} from 'react';
import Loading from "./Loading";
import {Card} from "./Card";
import {AppButton} from "./AppButton";
import {Tag} from "./Tag";
import {Link} from "react-router-dom";

const DATE_OPTIONS = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

const DAYS = [
    'dom'
    , 'lun'
    , 'mar'
    , 'mer'
    , 'gio'
    , 'ven'
    , 'sab'
]

export class Event extends Component {
    static displayName = Event.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , event: null
        }

        this.compatAppointments = this.compatAppointments.bind(this)
    }

    componentDidMount() {
        fetch("/api/v1/Events/" + this.props.match.params.id)
            .then(r => r.json())
            .then(r => {
                let state = {
                    ...this.state
                    , event: r
                    , isLoading: false
                    , appointments: []
                }

                this.setState(state)
                this.compatAppointments(r.appointments)
            })
    }

    compatAppointments(a) {
        let appointments = []

        for (let i = 0; i < a.length; i++) {

            let startTime = new Date(Date.parse(a[i].start_time))
            let startHour = startTime.getHours()
            let startMinutes = startTime.getMinutes()

            if (startMinutes !== 0)
                startHour += ':' + startMinutes.toString().padStart(2, '0')

            let endTime = new Date(Date.parse(a[i].end_time))
            let endHour = endTime.getHours()
            let endMinutes = endTime.getMinutes()

            if (endMinutes !== 0)
                endHour += ':' + endMinutes.toString().padStart(2, '0')

            let time = startHour + '-' + endHour

            let found = false
            for (let j = 0; j < appointments.length; j++) {
                if (appointments[j].time === time) {
                    found = true
                    appointments[j].days += ', ' + DAYS[a[i].day_of_the_week]
                }
            }

            if (!found) {
                appointments.push({
                    days: DAYS[a[i].day_of_the_week]
                    , time: time
                })
            }
        }

        this.setState({
            ...this.state
            , appointments: appointments
        })
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (<Loading/>)}
                {
                    !this.state.isLoading && this.state.event === null && (
                        <h3>Evento non trovato :-(</h3>
                    )
                }
                {
                    !this.state.isLoading && this.state.event !== null && (
                        <div>
                            <h2 style={{
                                textAlign: 'center',
                                marginTop: '20px'
                            }}>{this.state.event.name}</h2>
                            <Card>
                                <div>
                                    <div>
                                        {
                                            this.state.event.category !== null && (
                                                <Tag>{this.state.event.category.name}</Tag>
                                            )
                                        }
                                    </div>
                                </div>
                                <ul style={{
                                    listStyleType: 'none'
                                    , padding: 0
                                }}>
                                    <li><b>{this.state.event.park.name}</b></li>
                                    <li>{this.state.event.organizer.name}</li>
                                    <li>Dal <b>{new Date(Date.parse(this.state.event.start_date))
                                        .toLocaleDateString(undefined, DATE_OPTIONS)}</b> a <b>{new Date(Date.parse(this.state.event.end_date))
                                        .toLocaleDateString(undefined, DATE_OPTIONS)}</b></li>

                                    {
                                        this.state.appointments.map(a =>
                                            <li key={Math.random()}>{a.days} {a.time}</li>)
                                    }
                                </ul>

                                {
                                    this.state.event.note !== null && (
                                        <div style={{
                                            marginTop: '20px'
                                        }}>
                                            <b>Note:</b>
                                            <p>
                                                {this.state.event.note}
                                            </p>
                                        </div>
                                    )
                                }

                                <Link to={"/organizations/?organizationId=" + this.state.event.organizer.id}>
                                    <AppButton>
                                        Vai alla pagina dell'associazione
                                    </AppButton>
                                </Link>
                            </Card>
                        </div>
                    )}
            </div>
        );
    }

}
