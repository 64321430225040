import React from "react";
import './Loading.css'

export default class Loading extends React.Component {
    static displayName = Loading.name;
    
    render() {
        return(
            <div className="Loader">Loading...</div>
        )
    }
}