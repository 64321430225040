import React, {Component} from 'react';

export class Tag extends Component {
    static displayName = Tag.name;

    render() {
        return (
            <div style={{
                padding: '3px 5px'
                , margin: '0 4px 0 0'
                , borderRadius: '8px'
                , backgroundColor: '#24923e'
                , color: '#fff'
                , display: 'inline-block'
            }}>
                {this.props.children}
            </div>
        )
    }
}
