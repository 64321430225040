import React, {Component} from 'react';
import Loading from "./Loading";
import {AppButton} from "./AppButton";
import {Card} from "./Card";
import {Link} from "react-router-dom";
import {faTableTennis, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard} from "@fortawesome/free-regular-svg-icons";

export class Organizations extends Component {
    static displayName = Organizations.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , organizations: []
        }
        
        this.load = this.load.bind(this)
    }
    
    componentDidMount() {
        this.load()
    }
    
    componentWillReceiveProps() {
        this.load()
    }
    
    load() {
        const queryStrings = new URLSearchParams(this.props.history.location.search)

        fetch("/api/v1/Organization?" + queryStrings)
            .then(r => r.json())
            .then(r => {
                this.setState({
                    ...this.state
                    , isLoading: false
                    , organizations: r
                })
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}><FontAwesomeIcon icon={faUsers} style={{color: '#888'}}/> Associazioni <FontAwesomeIcon 
                    icon={faIdCard} style={{color: '#888'}}/></h1>
                {
                    this.state.isLoading && (<Loading/>)
                }
                {
                    this.state.organizations.map(m =>
                        <Card key={m.id}>
                            <h3>{m.name}</h3>
                            <ul style={{
                                listStyleType: 'none'
                                , padding: 0
                            }}>
                                {
                                    m.email && (
                                        <li>Email: <a href={"mailto:" + m.email}>{m.email}</a></li>
                                    )
                                }

                            </ul>

                            {
                                m.website && (
                                    <div style={{
                                        marginBottom: '10px'
                                    }}>
                                        <a href={m.website}
                                           target={"_blank"}>
                                            <AppButton isPrimary={false}>
                                                Vai al sito web
                                            </AppButton>
                                        </a>
                                    </div>)
                            }

                            <Link to={"/events/?organizationId=" + m.id}>
                                <AppButton>
                                    Vai alle attività
                                </AppButton>
                            </Link>
                        </Card>
                    )
                }
            </div>
        );
    }
}
