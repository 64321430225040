import React from "react";
import {Button, Spinner, Table} from "reactstrap";
import {Link} from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";

export default class Categories extends React.Component {
    static displayName = Categories.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , categories: []
        }
    }

    async componentDidMount() {
        fetch("/api/v1/Category", {
            headers: {
                'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(r => {
                this.setState({
                    ...this.state
                    , isLoading: false
                    , categories: r
                })
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Categorie</h1>
                <div style={{
                    marginBottom: '20px'
                }}>
                    <Link to={'/admin/categories/new'}>
                        <Button color="primary">Nuova categoria</Button>
                    </Link>
                </div>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome
                        </th>
                        <th>
                            Azioni
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.categories.length === 0 && (
                            <tr>
                                Nessuna categoria presente.
                            </tr>
                        )
                    }
                    {
                        this.state.categories.map(o => <tr>
                            <td>
                                {o.name}
                            </td>
                            <td>
                                <Link to={'/admin/categories/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>Modifica</Button>
                                </Link>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}