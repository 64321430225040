import React from "react";
import {Spinner, Table} from "reactstrap";
import authService from "../api-authorization/AuthorizeService";

const DATE_OPTIONS = {weekday: 'short', year: 'numeric', month: 'long', day: 'numeric'}

export default class Stats extends React.Component {
    static displayName = Stats.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , events: []
        }
    }

    async componentDidMount() {
        fetch("/api/v1/Events/stats", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                , 'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(r => {
                this.setState({
                    ...this.state
                    , isLoading: false
                    , events: r
                })
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Statistiche</h1>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome
                        </th>
                        <th>
                            Organizzatore
                        </th>
                        <th>
                            Inizio
                        </th>
                        <th>
                            Fine
                        </th>
                        <th>
                            Categoria
                        </th>
                        <th>
                            Uomini
                        </th>
                        <th>
                            Donne
                        </th>
                        <th>
                            Età massima
                        </th>
                        <th>
                            Età minima
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.events.length === 0 && (
                            <tr>
                                Nessuna statistica presente.
                            </tr>
                        )
                    }
                    {
                        this.state.events.map(o => <tr key={o.id}>
                            <td>
                                {o.name}
                            </td>
                            <td>
                                {o.organizer.name}
                            </td>
                            <td>
                                {new Date(Date.parse(o.start_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                {new Date(Date.parse(o.end_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                {o.category.name}
                            </td>
                            <td>
                                {o.stats.males}
                            </td>
                            <td>
                                {o.stats.females}
                            </td>
                            <td>
                                {o.stats.max_age}
                            </td>
                            <td>
                                {o.stats.min_age}
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}