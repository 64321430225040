import React from "react";
import {Alert, Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {MapContainer, TileLayer, Marker} from "react-leaflet";
import authService from "../api-authorization/AuthorizeService";
import ParkEdit from "./ParkEdit";

export default class ParkNew extends React.Component {
    static displayName = ParkEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
            , form: {
                lat: 44.2163406
                , lon: 12.0482844
                , name: null
            }
            , errors: []
        }

        this.handleMove = this.handleMove.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleMove(e) {
        let state = this.state
        this.state.form.lat = e.target._latlng.lat
        this.state.form.lon = e.target._latlng.lng

        this.setState(state)
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    async handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        let token = await authService.getAccessToken()

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            fetch('/api/v1/park', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + await authService.getAccessToken()
                }
                , method: 'post'
                , body: JSON.stringify(this.state.form)
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        this.props.history.push("/admin/parks")
                        return null;
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Aggiungi parco</h1>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Form onSubmit={this.handleSubmit}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <FormGroup>
                        <Label>Nome del parco:</Label>
                        <Input type={'text'}
                               placeholder={"Nome del parco"}
                               value={this.state.form.name}
                               maxLength={50}
                               name={'name'}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Trascina il marcatore per selezionare la posizone del parco:</Label>
                        <MapContainer style={{
                            width: '480px'
                            , height: '480px'
                        }}
                                      center={[44.2163406, 12.0482844]}
                                      zoom={13}
                                      scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                position={{
                                    lat: this.state.form.lat
                                    ,lon: this.state.form.lon
                                }}
                                draggable={true}
                                eventHandlers={{
                                    dragend: this.handleMove
                                }}
                            />
                        </MapContainer>
                    </FormGroup>
                    <Button color={'primary'} type={'submit'}>Crea</Button>
                </Form>

            </div>
        )
    }
}