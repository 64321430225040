import React from "react";
import {Button, Spinner, Table} from "reactstrap";
import {Link} from "react-router-dom";

export default class AdminOrganizations extends React.Component {
    static displayName = AdminOrganizations.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , organizations: []
        }
    }

    componentDidMount() {
        fetch("/api/v1/Organization")
            .then(r => r.json())
            .then(r => {
                this.setState({
                    ...this.state
                    , isLoading: false
                    , organizations: r
                })
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Associazioni</h1>
                <div style={{
                    marginBottom: '20px'
                }}>
                    <Link to={'/admin/organizations/new'}>
                        <Button color="primary">Nuova associazione</Button>
                    </Link>
                </div>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome
                        </th>
                        <th>
                            Sito web
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Azioni
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.organizations.length === 0 && (
                            <tr>
                                Nessuna associazione presente.
                            </tr>
                        )
                    }
                    {
                        this.state.organizations.map(o => <tr>
                            <td>
                                {o.name}
                            </td>
                            <td>
                                {o.website === null && (
                                    <span className={'text-muted'}>Non presente.</span>
                                )}
                                {
                                    o.website !== null && (
                                        <Link to={o.website} target={'_blank'}>{o.website}</Link>
                                    )
                                }
                            </td>
                            <td>
                                {o.email === null && (
                                    <span className={'text-muted'}>Non presente.</span>
                                )}
                                {
                                    o.email !== null && (
                                        <Link to={'mailto:' + o.email} target={'_blank'}>{o.email}</Link>
                                    )
                                }
                            </td>
                            <td>
                                <Link to={'/admin/organizations/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>
                                        Profilo dell'associazione
                                    </Button>
                                </Link>
                                <br/>
                                <Link to={'/admin/events?organizationId=' + o.id}>
                                    <Button color={'primary'} size={'sm'}>
                                        Eventi
                                    </Button>
                                </Link>
                                <br/>
                                <Link to={'/admin/users/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>
                                        Referenti
                                    </Button>
                                </Link>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}