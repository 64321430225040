import React from "react";
import {Button, Spinner, Table} from "reactstrap";
import {Link} from "react-router-dom";
import authService from "../api-authorization/AuthorizeService";

const DATE_OPTIONS = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

export default class EventsAdmin extends React.Component {
    static displayName = EventsAdmin.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , events: []
            , org: []
        }
    }

    async componentDidMount() {
        const queryStrings = new URLSearchParams(this.props.history.location.search)

        fetch("/api/v1/Events/NoLimits", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                , 'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(r => {
                let state = {
                    ...this.state
                    , isLoading: false
                    , org: r.organizations
                }
                
                const id = queryStrings.get('organizationId')
                
                if(id) {
                    state.events = r.events.filter(t => t.organizer.id === id)
                } else {
                    state.events = r.events
                }
                
                this.setState(state)
            })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Attività{this.state.org.length === 1 ? ' di ' + this.state.org[0].name : ''}</h1>
                <div style={{
                    marginBottom: '20px'
                }}>
                    <Link to={'/admin/events/new'}>
                        <Button color="primary">Nuova attività</Button>
                    </Link>
                </div>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Nome
                        </th>
                        <th>
                            Organizzatore
                        </th>
                        <th>
                            Parco
                        </th>
                        <th>
                            Inizio
                        </th>
                        <th>
                            Fine
                        </th>
                        <th>
                            Visibile
                        </th>
                        <th>
                            Azioni
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.events.length === 0 && (
                            <tr>
                                Nessuna attività presente.
                            </tr>
                        )
                    }
                    {
                        this.state.events.map(o => <tr key={o.id}>
                            <td>
                                {o.name}
                            </td>
                            <td>
                                {o.organizer.name}
                            </td>
                            <td>
                                {o.park.name}
                            </td>
                            <td>
                                {new Date(Date.parse(o.start_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                {new Date(Date.parse(o.end_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                {
                                    !o.suspended && (
                                        <span className={'text-muted'}>Sì.</span>
                                    )
                                }
                                {
                                    o.suspended && (
                                        <span className={'text-danger'}>No</span>
                                    )
                                }
                            </td>
                            <td>
                                <Link to={'/admin/events/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>Modifica attività</Button>
                                </Link>
                                <br/>
                                <Link to={'/admin/appointments/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>Modifica appuntamenti</Button>
                                </Link>
                                <br/>
                                <Link to={'/admin/stats/' + o.id}>
                                    <Button color={'primary'} size={'sm'}>Statistiche</Button>
                                </Link>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}