import React, {Component} from 'react';
import Loading from "./Loading";
import {Card} from "./Card";
import {AppButton} from "./AppButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTree, faCloudSun
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Enumerable from "linq";

export default class Parks extends Component {
    static displayName = Parks.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , parks: []
            , events: []
        }

        this.load = this.load.bind(this)
        this.countForPark = this.countForPark.bind(this)
    }


    componentDidMount() {
        this.load()
    }

    componentWillReceiveProps(props) {
        this.load()
    }

    load() {
        this.setState({
            ...this.state
            , isLoading: true
        }, () => {


            fetch("/api/v1/Events")
                .then(r => r.json())
                .then(r => {
                    let state = {
                        ...this.state
                        , parks: r.parks
                        , events: r.events
                        , isLoading: false
                    }

                    this.setState(state)
                })
        })

    }
    
    countForPark(parkId) {
        const count = Enumerable.from(this.state.events).count(e => e.park.id === parkId)
        
        if(count === 1) {
            return <p>Un'attività nei prossimi sette giorni.</p>
        }
        
        return <p>{count} attività nei prossimi sette giorni.</p>
    }

    render() {
        
        
        return (
            <div>
                <h1 style={{
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    <FontAwesomeIcon icon={faTree}
                                     style={{color: '#888'}}/> Parchi <FontAwesomeIcon
                    icon={faCloudSun} style={{color: '#888'}}/>
                </h1>
                {
                    this.state.isLoading && (
                        <Loading/>
                    )
                }
                {
                    !this.state.isLoading && this.state.parks.length === 0 && (
                        <h3 style={{
                            textAlign: 'center'
                        }}>Nessun parco presente :-(</h3>
                    )
                }
                {
                    this.state.parks.map(p =>
                        <Card key={p.id}>
                            <h3>{p.name}</h3>
                            {
                                this.countForPark(p.id)
                            }
                            <Link to={"/events?parkId=" + p.id}>
                                <AppButton>
                                    Vai alle attività
                                </AppButton>
                            </Link>
                        </Card>
                    )
                }
            </div>
        );
    }
}
