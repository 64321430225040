import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import {ApplicationPaths} from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import {Layout} from "./components/Layout";
import {LayoutApp} from "./components/LayoutApp";
import {Home} from "./components/Home";
import {Events} from "./components/Events";
import {Organizations} from "./components/Organizations";
import {Event} from "./components/Event";
import Info from "./components/Info";
import AdminHome from "./components/admin/AdminHome";
import AdminOrganizations from "./components/admin/AdminOrganizations";
import NewOrganization from "./components/admin/NewOrganization";
import OrganizationEdit from "./components/admin/OrganizationEdit";
import ParksPublic from "./components/Parks";
import Parks from "./components/admin/Parks";
import ParkNew from "./components/admin/ParkNew";
import ParkEdit from "./components/admin/ParkEdit";
import Categories from "./components/admin/Categories";
import CategoryNew from "./components/admin/CategoryNew";
import CategoryEdit from "./components/admin/CategoryEdit";
import EventsAdmin from "./components/admin/EventsAdmin";
import EventsNew from "./components/admin/EventsNew";
import EventsEdit from "./components/admin/EventsEdit";
import Appointments from "./components/admin/Appointments";
import Users from "./components/admin/Users";
import Stats from "./components/admin/Stats";
import Stat from "./components/admin/Stat";
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Switch>
            <Route exact path={['/', '/map', '/events', '/organizations', '/event/:id', '/info']}>
                <LayoutApp>
                    <Route exact path='/' component={ParksPublic}/>
                    <Route exact path='/events' component={Events}/>
                    <Route exact path='/map' component={Home}/>
                    <Route exact path='/organizations' component={Organizations}/>
                    <Route exact path='/event/:id' component={Event}/>
                    <Route exact path='/info' component={Info}/>
                </LayoutApp>
            </Route>
            <Route path={['/admin', ApplicationPaths.ApiAuthorizationPrefix]}>
                <Layout>
                    <Route exact path='/admin/' component={AdminHome}/>
                    <Switch>
                        <AuthorizeRoute exact path='/admin/organizations' component={AdminOrganizations}/>
                        <AuthorizeRoute exact path='/admin/organizations/new' component={NewOrganization}/>
                        <AuthorizeRoute exact path='/admin/organizations/:id' component={OrganizationEdit}/>
                        <AuthorizeRoute exact path='/admin/parks' component={Parks}/>
                        <AuthorizeRoute exact path='/admin/parks/new' component={ParkNew}/>
                        <AuthorizeRoute exact path='/admin/parks/:id' component={ParkEdit}/>
                        <AuthorizeRoute exact path='/admin/categories' component={Categories}/>
                        <AuthorizeRoute exact path='/admin/categories/new' component={CategoryNew}/>
                        <AuthorizeRoute exact path='/admin/categories/:id' component={CategoryEdit}/>
                        <AuthorizeRoute exact path='/admin/events/' component={EventsAdmin}/>
                        <AuthorizeRoute exact path='/admin/events/new' component={EventsNew}/>
                        <AuthorizeRoute exact path='/admin/events/:id' component={EventsEdit}/>
                        <AuthorizeRoute exact path='/admin/appointments/:id' component={Appointments}/>
                        <AuthorizeRoute exact path='/admin/users/:id?' component={Users}/>
                        <AuthorizeRoute exact path='/admin/stats/' component={Stats}/>
                        <AuthorizeRoute exact path='/admin/stats/:id' component={Stat}/>
                    </Switch>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes}/>
                </Layout>
            </Route>
        </Switch>
    </BrowserRouter>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

