import React from "react";
import {Alert, Button, Form, FormGroup, Input, Label, Spinner, Table} from "reactstrap";
import authService from "../api-authorization/AuthorizeService";

const DATE_OPTIONS = {hour: 'numeric', minute: 'numeric'}

const DAYS = [
    'dom'
    , 'lun'
    , 'mar'
    , 'mer'
    , 'gio'
    , 'ven'
    , 'sab'
]

export default class Appointments extends React.Component {
    static displayName = Appointments.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
            , appointments: []
            , errors: []
            , form: {
                day_of_the_week: 0
                , start_time: null
                , end_time: null
            }
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.load = this.load.bind(this)
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    async handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        let token = await authService.getAccessToken()

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, () => {
            fetch('/api/v1/Events/' + this.props.match.params.id + '/appointments', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + token
                }
                , method: 'post'
                , body: JSON.stringify({
                    day_of_the_week: parseInt(this.state.form.day_of_the_week)
                    ,
                    start_time: '2021-05-04T' + (this.state.form.start_time === null ? '00:00' : this.state.form.start_time) + ':00'
                    ,
                    end_time: '2021-05-04T' + (this.state.form.end_time === null ? '00:00' : this.state.form.end_time) + ':00'
                })
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        this.load()
                        return null;
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    async delete(id) {
        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            fetch("/api/v1/events/appointment/" + id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + await authService.getAccessToken()
                }
                , method: 'delete'
            }).then(r => {
                if (r.status === 204 || r.status === 200) {
                    this.load()
                    return null;
                }

                return r.json()
            })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    async load() {
        fetch("/api/v1/Events/NoLimits", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                , 'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(r => {
                let appointments = []

                for (let i = 0; i < r.events.length; i++) {
                    if (r.events[i].id === this.props.match.params.id) {
                        appointments = r.events[i].appointments
                        console.log(r.events[i])
                        break;
                    }
                }
                console.log(appointments)
                this.setState({
                    ...this.state
                    , isLoading: false
                    , appointments: appointments
                })
            })
    }

    async componentDidMount() {
        await this.load()
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Appuntamenti</h1>
                <div style={{
                    marginBottom: '20px'
                }}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <Form onSubmit={this.handleSubmit}>
                        <div className={'form-row'}>
                            <FormGroup style={{
                                marginRight: '12px'
                            }}>
                                <Label for={'day_of_the_week'}>Giorno della setimana</Label>
                                <select className="form-control"
                                        id={'day_of_the_week'}
                                        name={'day_of_the_week'}
                                        value={this.state.form.day_of_the_week}
                                        onChange={this.handleChange}>
                                    {DAYS.map((d, i) => <option key={i} value={i}>{d}</option>)}
                                </select>
                            </FormGroup>
                            <FormGroup style={{
                                marginRight: '12px'
                            }}>
                                <Label for={'start_time'}>Orario inizio</Label>
                                <Input type={'time'}
                                       id={'start_time'}
                                       name={'start_time'}
                                       onChange={this.handleChange}
                                       value={this.state.form.start_time}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for={'end_time'}>Orario fine</Label>
                                <Input type={'time'}
                                       id={'end_time'}
                                       name={'end_time'}
                                       onChange={this.handleChange}
                                       value={this.state.form.end_time}/>
                            </FormGroup>
                        </div>
                        <div className={'form-row'}>
                            <FormGroup>
                                <Button color="primary" type={'submit'}>Aggiungi</Button>
                            </FormGroup>
                        </div>
                    </Form>
                </div>

                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Giorno
                        </th>
                        <th>
                            Orario inizio
                        </th>
                        <th>
                            Orario fine
                        </th>
                        <th>
                            Azioni
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        !this.state.isLoading && this.state.appointments.length === 0 && (
                            <tr>
                                Nessun appuntamento presente.
                            </tr>
                        )
                    }
                    {
                        this.state.appointments.map(o => <tr key={o.id}>
                            <td>
                                {DAYS[o.day_of_the_week]}
                            </td>
                            <td>
                                {new Date(Date.parse(o.start_time))
                                    .toLocaleTimeString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                {new Date(Date.parse(o.end_time))
                                    .toLocaleTimeString(undefined, DATE_OPTIONS)}
                            </td>
                            <td>
                                <Button color={'danger'} onClick={() => this.delete(o.id)}>Elimina</Button>
                            </td>
                        </tr>)
                    }
                    </tbody>
                </Table>
            </div>
        )
    }
}