import React from "react";
import {Alert, Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {MapContainer, TileLayer, Marker} from "react-leaflet";
import authService from "../api-authorization/AuthorizeService";

export default class Stat extends React.Component {
    static displayName = Stat.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
            , form: {
                males: null
                , females: null
                , max_age: null
                , min_age: null
            }
            , errors: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        fetch("/api/v1/events/stats/" + this.props.match.params.id, {
            headers: {
                'Authorization': 'Bearer ' + await authService.getAccessToken()
            }
        })
            .then(r => r.json())
            .then(r => {

                this.setState({
                    ...this.state
                    , isLoading: false
                    , form: r
                })
            })
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    async handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        let token = await authService.getAccessToken()

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, async () => {
            fetch('/api/v1/events/stats/' + this.props.match.params.id, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + token
                }
                , method: 'post'
                , body: JSON.stringify(this.state.form)
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        let state = this.state
                        state.isLoading = false
                        this.setState(state)
                        return null;
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Modifica statistica</h1>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Form onSubmit={this.handleSubmit}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <FormGroup>
                        <Label>Numero uomini:</Label>
                        <Input type={'number'}
                               min={0}
                               placeholder={"Numero uomini"}
                               value={this.state.form.males}
                               name={'males'}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Numero donne:</Label>
                        <Input type={'number'}
                               min={0}
                               placeholder={"Numero donne"}
                               value={this.state.form.females}
                               name={'females'}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Età massima:</Label>
                        <Input type={'number'}
                               min={0}
                               placeholder={"Età massima"}
                               value={this.state.form.max_age}
                               name={'max_age'}
                               onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Età minima:</Label>
                        <Input type={'number'}
                               min={0}
                               placeholder={"Età minima"}
                               value={this.state.form.min_age}
                               name={'min_age'}
                               onChange={this.handleChange}/>
                    </FormGroup>

                    <Button color={'primary'} type={'submit'}>Modifica</Button>
                </Form>

            </div>
        )
    }
}