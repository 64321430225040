import React, {Component} from 'react';

export class AppButton extends Component {
    static displayName = AppButton.name;

    render() {
        let bgColor = '#d1d1d1'
        let txtColor = '#000'

        let isPrimary = this.props.isPrimary

        if (isPrimary === null || isPrimary === undefined) {
            isPrimary = true
        }

        if (isPrimary) {
            bgColor = '#24923e'
            txtColor = '#fff'
        }

        return (
            <div style={{
                background: bgColor
                , color: txtColor
                , borderRadius: '12px'
                , fontSize: '18px'
                , padding: '8px 0'
                , textAlign: 'center'
                , fontWeight: 'bold'
            }}>
                {this.props.children}
            </div>
        )
    }
}
