import React from "react";

export default class MapMarker extends React.Component {
    render() {
        return (
            <div style={{
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' 
                ,height: '30px'
                ,width: '30px'
                ,borderRadius: '50%'
                ,textAlign: 'center'
                ,marginRight: '-30px'
                ,marginTop: '-30px'
                ,background: '#ff3b30'
                ,fontSize: '15px'
                ,lineHeight: '30px'
                ,fontWeight: 'bold'
                ,color: '#fff'
            }}>
                {this.props.value}
            </div>

        )
    }
}