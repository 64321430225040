import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export default class TopBar extends React.Component {
    static displayName = TopBar.name;

    render() {
        return (
            <div style={{
                padding: '8px 0'
                , maxWidth: '860px'
                , margin: '0 auto'
                , background: '#fff'
                , textAlign: 'center'
                , position: 'relative'
                , borderBottom: '1px solid #c4c4c4'
            }}>
                <div style={{
                    position: 'absolute'
                    , left: '20px'
                }}>
                    <img src={"/logo-ente.png"}
                         alt={"Comune di Forlì"}
                         style={{
                             maxHeight: '75px'
                         }}/>
                </div>
                <div>
                    <img src={"/logo.png"}
                         alt={"Sport a Forlì"}
                         style={{
                             maxHeight: '75px'
                         }}/>
                </div>
                <div style={{
                    position: 'absolute'
                    , right: '20px'
                    , top: '27px'
                }}>
                    <Link to={"/info"}
                          style={{
                              color: '#24923e'
                              , fontSize: '25px'
                          }}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                    </Link>
                </div>
            </div>
        )
    }
}