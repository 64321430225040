import React from "react";
import {Link} from "react-router-dom";
import {AppButton} from "./AppButton";

export default class Info extends React.Component {
    static displayName = Info.name;

    render() {
        return (
            <div style={{
                margin: '24px'
            }}>
                <div>
                    <img src={"/logo-ente.png"} style={{
                        maxWidth: '90px'
                    }} alt/>
                    <h1>Comune di <b>Forlì</b></h1>
                </div>
                <b>Unità Sport</b>
                <p>Viale della Libertà, 2<br/>
                    c/o Casa dello Sport 'Adriano Casadei' (ex G.I.L.)<br/>
                    Tel. <a href={"tel:+390543712240"}>0543 712240</a> - <a href={"tel:+390543712411"}>712411</a><br/>
                    Email <a href={"mail:unita.sport@comune.forli.fc.it"}>unita.sport@comune.forli.fc.it</a></p>

                <b>Ambito funzionale Manifestazioni ed Eventi sportivi</b>
                <p>Tel. <a href={"tel:+390543712402"}>0543 712402</a><br/>
                    Cell. <a href={"tel:+393204322943"}>320 432 2943</a><br/>
                    Email <a
                        href={"mail:isabella.cicognani@comune.forli.fc.it"}>isabella.cicognani@comune.forli.fc.it</a>
                </p>
                <div>
                    <div style={{
                        marginBottom: '20px'
                    }}>
                        <a href={"http://www.comune.forli.fc.it"} target={'_blank'}>
                            <AppButton>
                                Vai al sito del comune
                            </AppButton>
                        </a>
                    </div>

                    <Link to={"/admin"}>
                        <AppButton>
                            Area riservata
                        </AppButton>
                    </Link>
                </div>
                <div style={{
                    marginTop: '24px'
                }}>
                    <a href="https://www.iubenda.com/privacy-policy/92623180"
                       className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                       title="Privacy Policy ">Privacy Policy</a>
                    <script type="text/javascript">{`
                        (function (w, d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})
                        (window, document);
                    `}</script>
                </div>
                <div style={{
                    marginTop: '24px'
                }}>
                    <b>Realizzato da</b>
                    <p>FMI Srl<br/>Area Sviluppo Tecnologico</p>
                    <img src={"https://www.fmi.fc.it/wp-content/uploads/2016/05/FMI-logo-new-.jpg"} alt/>
                </div>
            </div>
        )
    }
}