import React from "react";
import {NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom"
import {
    faMapMarkedAlt,
    faStream,
    faBuilding,
    faUsers,
    faVolleyballBall,
    faTree
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import './BottomNav.css'

export default class BottomNav extends React.Component {

    render() {
        return (
            <div className="BtnNavContainer">
                <NavItem>
                    <NavLink tag={Link} className="Link" to="/">
                        <FontAwesomeIcon icon={faTree} className="Icon"/>
                        <span>Parchi</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="Link" to="/map">
                        <FontAwesomeIcon icon={faMapMarkedAlt} className="Icon"/>
                        <span>Mappa</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="Link" to="/events">
                        <FontAwesomeIcon icon={faVolleyballBall} className="Icon"/>
                        <span>Attività</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="Link" to="/organizations">
                        <FontAwesomeIcon icon={faUsers} className="Icon"/>
                        <span>Associazioni</span>
                    </NavLink>
                </NavItem>
            </div>
        )
    }
}