import React from "react";
import authService from "../api-authorization/AuthorizeService";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {ApplicationPaths} from "../api-authorization/ApiAuthorizationConstants";
import './AdminHome.css'

export default class AdminHome extends React.Component {
    static displayName = AdminHome.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false
        }
    }

    async componentDidMount() {
        this.setState({
            ...this.state
            , isAuthenticated: await authService.isAuthenticated()
            , user: await authService.getUser()
        })
    }

    render() {
        return <div>
            <h1>Benvenuto nell'area di<br/>gestione di Sport a Forlì!</h1>
            {
                !this.state.isAuthenticated && (
                    <div>
                        <h3>Accedi per continuare</h3>
                        <Link to={ApplicationPaths.Login}>
                            <Button color={'primary'} size={'lg'}>Accedi</Button>
                        </Link>
                    </div>
                )
            }
            {
                this.state.isAuthenticated && (
                    <div style={{
                        marginTop: '20px'
                    }}>
                        <h3>Menù</h3>
                        <ul style={{
                            listStyleType: 'none'
                        }}>
                            <Link to={'/admin/events'}>
                                <Button color={'primary'}>
                                    Tutti le attività
                                </Button>
                            </Link>
                            {
                                this.state.user.org !== undefined && (
                                    <div>
                                        <li>
                                            <Link to={'/admin/organizations/' + this.state.user.org}>
                                                <Button color={'primary'}>La mia associazione</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/users/' + this.state.user.org}>
                                                <Button color={'primary'}>I miei referenti</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/stats/'}>
                                                <Button color={'primary'}>La mie statistiche</Button>
                                            </Link>
                                        </li>
                                    </div>
                                )
                            }

                            {
                                this.state.user.org === undefined && (
                                    <div>
                                        <li>
                                            <Link to={'/admin/organizations/'}>
                                                <Button color={'primary'}>Tutte le associazioni</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/users/'}>
                                                <Button color={'primary'}>Referenti comunali</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/categories/'}>
                                                <Button color={'primary'}>Categorie attività</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/parks/'}>
                                                <Button color={'primary'}>Parchi</Button>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/admin/stats/'}>
                                                <Button color={'primary'}>Statistiche</Button>
                                            </Link>
                                        </li>
                                    </div>
                                )
                            }
                        </ul>
                    </div>
                )
            }

        </div>
    }
}