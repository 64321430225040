import React, {Component} from 'react';
import {Alert, Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import authService from "../api-authorization/AuthorizeService";

export default class EventsNew extends Component {
    static displayName = EventsNew.name;

    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: null
                , note: null
                , suspended: false
                , category: null
                , organizer: null
                , start_date: null
                , end_date: null
                , park: null
            }
            , errors: []
            , isLoading: true
            , organizations: []
            , parks: []
            , categories: []
            , forceOrg: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
    }

    async componentDidMount() {
        let user = await authService.getUser()

        fetch('/api/v1/organization')
            .then(r => r.json())
            .then(r => {
                this.setState({
                    ...this.state
                    , organizations: r
                    , form: {
                        ...this.state.form
                        , organizer: user.org !== undefined ? user.org : (r.length > 0 ? r[0].id : null)
                    }
                    , forceOrg: user.org !== undefined ? user.org : null
                }, async () => {

                    fetch('/api/v1/park', {
                        headers: {
                            'Authorization': 'Bearer ' + await authService.getAccessToken()
                        }
                    })
                        .then(r => r.json())
                        .then(r => {
                            this.setState({
                                ...this.state
                                , parks: r
                                , form: {
                                    ...this.state.form
                                    , park: r.length > 0 ? r[0].id : null
                                }
                            }, async () => {
                                fetch('/api/v1/category', {
                                    headers: {
                                        'Authorization': 'Bearer ' + await authService.getAccessToken()
                                    }
                                })
                                    .then(r => r.json())
                                    .then(r => {
                                        this.setState({
                                            ...this.state
                                            , isLoading: false
                                            , categories: r
                                            , form: {
                                                ...this.state.form
                                                , category: r.length > 0 ? r[0].id : null
                                            }
                                        })
                                    })
                            })
                        })
                })
            })
    }

    handleChange(event) {
        let state = this.state
        let v = event.target.value
        state.form[event.target.name] = v === '' ? null : v
        this.setState(state);
    }

    handleCheckbox(event) {
        let state = this.state
        let v = event.target.checked
        state.form[event.target.name] = v
        this.setState(state);
    }

    async handleSubmit(event) {
        event.preventDefault()

        if (this.state.isLoading) return

        let token = await authService.getAccessToken()

        this.setState({
            ...this.state
            , isLoading: true
            , errors: []
        }, () => {
            fetch('/api/v1/events', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    , 'Authorization': 'Bearer ' + token
                }
                , method: 'post'
                , body: JSON.stringify(this.state.form)
            })
                .then(r => {
                    if (r.status === 204 || r.status === 200) {
                        this.props.history.push("/admin/events")
                        return null;
                    }

                    return r.json()
                })
                .then(r => {
                    if (r === null) {
                        return;
                    }

                    let state = this.state
                    Object.keys(r.errors).forEach((value) => {
                        state.errors.push(...r.errors[value])
                    })
                    state.isLoading = false
                    this.setState(state)
                })
        })
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center'
                    , marginTop: '20px'
                }}>Nuova attività</h1>
                {
                    this.state.isLoading && (
                        <div>
                            <Spinner size="sm" color="primary"/> Caricamento in corso...
                        </div>
                    )
                }
                <Form onSubmit={this.handleSubmit}>
                    {
                        this.state.errors.length > 0 && (
                            <Alert color="danger">
                                <ul>
                                    {this.state.errors.map(e => <li key={Math.random()}>{e}</li>)}
                                </ul>
                            </Alert>
                        )
                    }
                    <FormGroup>
                        <Label>Nome dell'attività:</Label>
                        <Input type={'text'}
                               placeholder={'Nome dell\'attività'}
                               name={'name'}
                               required={true}
                               value={this.state.form.name}
                               minLength={1}
                               maxLength={50}
                               onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Associazione:</Label>
                        <select className="form-control"
                                required={true}
                                disabled={this.state.forceOrg !== null}
                                onChange={this.handleChange}
                                name={'organizer'}
                                value={this.state.forceOrg !== null ? this.state.forceOrg : this.state.form.organizer}>
                            {
                                this.state.organizations.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                            }
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label>Parco:</Label>
                        <select className="form-control"
                                required={true}
                                value={this.state.form.park}
                                onChange={this.handleChange}
                                name={'park'}>
                            {
                                this.state.parks.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                            }
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label>Categoria:</Label>
                        <select className="form-control"
                                value={this.state.form.category}
                                onChange={this.handleChange}
                                name={'category'}>
                            {
                                this.state.categories.map(p => <option key={p.id} value={p.id}>{p.name}</option>)
                            }
                        </select>
                    </FormGroup>
                    <div className={'form-check'}>
                        <Input type={'checkbox'}
                               name={'suspended'}
                               checked={this.state.form.suspended}
                               className={'form-check-input'}
                               onChange={this.handleCheckbox}
                               id={'visibileNew'}
                        />
                        <Label className={'form-check-label'}
                               for={'visibileNew'}>Non visibile?</Label>

                    </div>
                    <FormGroup>
                        <Label>Data inizio:</Label>
                        <Input type={'date'}
                               name={'start_date'}
                               required={true}
                               value={this.state.form.start_date}
                               onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Data fine:</Label>
                        <Input type={'date'}
                               required={true}
                               name={'end_date'}
                               onChange={this.handleChange}
                               value={this.state.form.end_date}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Note:</Label>
                        <textarea name={'note'}
                                  value={this.state.form.note}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  rows={5}
                        />
                    </FormGroup>
                    <Button color={'primary'} type="submit">Crea</Button>
                </Form>
            </div>
        );
    }
}
