import React, {Component} from 'react';
import Loading from "./Loading";
import {Card} from "./Card";
import {AppButton} from "./AppButton";
import {Tag} from "./Tag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolleyballBall, faFutbol, faTableTennis, faRunning, faHiking} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {FormGroup, Input, Label} from "reactstrap";

const DATE_OPTIONS = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}

export class Events extends Component {
    static displayName = Events.name;

    constructor(props) {
        super(props);

        this.state = {
            filterOpen: false
            , isLoading: true
            , parkName: null
            , organizationName: null
            , events: []
            , categories: []
            , parks: []
            , organizations: []
            , parkId: null
            , organizationId: null
            , categoryId: null
        }

        this.toggle = this.toggle.bind(this)
        this.onCategoryChanged = this.onCategoryChanged.bind(this)
        this.onParkChanged = this.onParkChanged.bind(this)
        this.onOrganizationChanged = this.onOrganizationChanged.bind(this)
        this.onQueryChanged = this.onQueryChanged.bind(this)
        this.load = this.load.bind(this)
    }


    componentDidMount() {
        this.load()
    }

    componentWillReceiveProps(props) {
        this.load()
    }

    load() {
        const queryStrings = new URLSearchParams(this.props.history.location.search)
        
        this.setState({
            ...this.state
            , isLoading: true
        }, () => {

            this.setState({
                ...this.state
                , parkId: queryStrings.get('parkId')
                , organizationId: queryStrings.get('organizationId')
                , categoryId: queryStrings.get('categoryId')
            }, () => {

                queryStrings.delete('categoryId')

                fetch("/api/v1/Events?" + queryStrings)
                    .then(r => r.json())
                    .then(r => {
                        let state = {
                            ...this.state
                            , events: r.events
                            , categories: r.categories
                            , parks: r.parks
                            , organizations: r.organizations
                            , isLoading: false
                        }

                        if (r.parks.length === 1 && this.state.parkId) {
                            state.parkName = r.parks[0].name
                        } else {
                            state.parkName = null
                        }

                        if (r.organizations.length === 1 && this.state.organizationId) {
                            state.organizationName = r.organizations[0].name
                        } else {
                            state.organizationName = null
                        }

                        if(!this.state.categoryId && (this.state.organizationId || this.state.parkId)) {
                            state.events = r.events
                        } else {
                            state.events = r.events.filter(e => e.category.id === this.state.categoryId)
                        }
                        
                        this.setState(state)
                    })
            })
        })
    }

    toggle() {
        this.setState({
            ...this.state
            , filterOpen: !this.state.filterOpen
        })
    }

    onCategoryChanged(e) {
        this.onQueryChanged('categoryId', e.target.value)
    }

    onParkChanged(e) {
        this.onQueryChanged('parkId', e.target.value)
    }

    onOrganizationChanged(e) {
        this.onQueryChanged('organizationId', e.target.value)
    }

    onQueryChanged(param, value) {
        const queryStrings = new URLSearchParams(this.props.history.location.search)

        if (value !== '')
            queryStrings.set(param, value)

        else
            queryStrings.delete(param)

        this.props.history.push("/events/?" + queryStrings)
        this.load()
    }

    render() {
        return (
            <div>
                <h1 style={{
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    <FontAwesomeIcon icon={faTableTennis} style={{color: '#888', marginRight: '10px'}}/>
                    <FontAwesomeIcon icon={faVolleyballBall} style={{color: '#888'}}/> Attività <FontAwesomeIcon
                    icon={faHiking} style={{color: '#888', marginRight: '10px'}}/>
                    <FontAwesomeIcon icon={faFutbol} style={{color: '#888'}}/>
                </h1>
                {
                    this.state.isLoading && (
                        <Loading/>
                    )
                }
                {
                    !this.state.isLoading && this.state.events.length === 0 && this.state.organizationId && (
                        <h3 style={{
                            textAlign: 'center'
                        }}>Nessuna attività presente :-(</h3>
                    )
                }

                {
                    !this.state.isLoading && this.state.events.length === 0 && !this.state.organizationId && (
                        <h3 style={{
                            textAlign: 'center'
                        }}>Seleziona una categoria di attività</h3>
                    )
                }

                <div style={{
                    display: 'table'
                    , margin: '12px'
                    , width: 'calc(100% - 24px)'
                }}>

                    <div style={{
                        display: 'table-cell'
                        , verticalAlign: 'middle'
                    }}>
                        {
                            this.state.parkName !== null && (
                                <p>Stai guardando le attività del <b>{this.state.parkName}</b>.</p>
                            )
                        }

                        {
                            this.state.organizationName !== null && (
                                <p>Stai guardando le attività dell'associazione <b>{this.state.organizationName}</b>.</p>
                            )
                        }
                    </div>
                </div>
                <div className={'mx-4'}>
                    <FormGroup>
                        <Label for={'select_category'}>Categoria:</Label>
                        <Input type={'select'}
                                id={'select_category'}
                                className={'form-control'}
                                onChange={this.onCategoryChanged}
                                value={this.state.categoryId ?? ''}>
                            <option/>
                            {
                                this.state.categories.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
                            }
                        </Input>
                    </FormGroup>
                </div>

                {
                    this.state.events.map(e =>
                        <Card key={e.id}>
                            <h3>{e.name}</h3>
                            <div>
                                {
                                    e.category !== null && (
                                        <Tag>{e.category.name}</Tag>
                                    )
                                }
                            </div>
                            <ul style={{
                                listStyleType: 'none'
                                , padding: 0
                            }}>
                                {
                                    !this.state.parkName && (
                                        <li><b>{e.park.name}</b></li>
                                    )
                                }

                                {
                                    !this.state.organizationName && (
                                        <li><b>{e.organizer.name}</b></li>
                                    )
                                }
                                <li>Da <b>{new Date(Date.parse(e.start_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}</b> a <b>{new Date(Date.parse(e.end_date))
                                    .toLocaleDateString(undefined, DATE_OPTIONS)}</b>
                                </li>
                            </ul>

                            <Link to={"/event/" + e.id}>
                                <AppButton>
                                    Ulteriori informazioni
                                </AppButton>
                            </Link>
                        </Card>
                    )
                }
            </div>
        );
    }
}
