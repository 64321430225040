import React, {Component} from 'react';
import BottomNav from "./BottomNav";
import TopBar from "./TopBar";

export class LayoutApp extends Component {
    static displayName = LayoutApp.name;

    render() {
        return (
            <div style={{
                background: '#000'
                , width: '100vw'
                , height: '100vh'
            }}>
                <TopBar/>
                <div style={{
                    position: 'absolute'
                    , left: 0
                    , right: 0
                    , top: '92px'
                    , bottom: '10vh'
                    , overflowY: 'scroll'
                    , maxWidth: '860px'
                    , margin: '0 auto'
                    , background: '#fff'
                }}>
                    {this.props.children}
                </div>
                <BottomNav/>
            </div>
        );
    }
}
